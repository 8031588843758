<template>
  <v-card class="mx-auto" outlined :loading="loading" elevation="0">
    <v-subheader class="title"> Search results for: "{{  query  }}" </v-subheader>
    <v-expansion-panels flat>
      <v-expansion-panel>
        <v-expansion-panel-header>
          IAOs ({{  results.incidents ? results.incidents.length : 0  }})
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <incident-summary-table :items="results.incidents" />
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header>
          Cases ({{ results.cases ? results.cases.length : 0 }})
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <case-summary-table :items="results.cases" />
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header>
          Tasks ({{  results.tasks ? results.tasks.length : 0  }})
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <task-summary-table :items="results.tasks" />
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header>
          Sources ({{  results.sources ? results.sources.length : 0  }})
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <source-summary-table :items="results.sources" />
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header>
          Queries ({{  results.queries ? results.queries.length : 0  }})
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <query-summary-table :items="results.sources" />
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header>
          Documents ({{  results.documents ? results.documents.length : 0  }})
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <document-summary-table :items="results.documents" />
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header>
          Tags ({{  results.tags ? results.tags.length : 0  }})
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <tag-summary-table :items="results.tags" />
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header>
          Commands ({{  results.commands ? results.commands.length : 0  }})
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <command-summary-table :items="results.commands" />
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header>
          Commits ({{  results.commits ? results.commits.length : 0  }})
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <commit-summary-table :items="results.commits" />
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header>
          Signal Instances ({{  results.signal_instances ? results.signal_instances.length : 0  }})
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <signal-instance-summary-table :items="results.signal_instances" />
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header>
          Weblinks ({{  results.weblinks ? results.weblinks.length : 0  }})
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <weblink-summary-table :items="results.weblinks" />
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header>
          Webcrawls ({{  results.webcrawls ? results.webcrawls.length : 0  }})
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <webcrawl-summary-table :items="results.webcrawls" />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-card>
</template>

<script>
import { mapState } from "vuex"
import IncidentSummaryTable from "@/incident/IncidentSummaryTable.vue"
import CaseSummaryTable from "@/case/CaseSummaryTable.vue"
import TaskSummaryTable from "@/task/TaskSummaryTable.vue"
import SourceSummaryTable from "@/data/source/SourceSummaryTable.vue"
import QuerySummaryTable from "@/data/query/QuerySummaryTable.vue"
import DocumentSummaryTable from "@/document/DocumentSummaryTable.vue"
import TagSummaryTable from "@/tag/TagSummaryTable.vue"
import CommandSummaryTable from "@/data/command/CommandSummaryTable.vue"
import CommitSummaryTable from "@/commit/CommitSummaryTable.vue"
import SignalInstanceSummaryTable from "@/signal/SignalInstanceSummaryTable.vue"
import WeblinkSummaryTable from "@/data/weblink/WeblinkSummaryTable.vue"
import WebcrawlSummaryTable from "@/webcrawl/WebcrawlSummaryTable.vue"

export default {
  name: "SearchResultList",
  components: {
    IncidentSummaryTable,
    CaseSummaryTable,
    TaskSummaryTable,
    DocumentSummaryTable,
    SourceSummaryTable,
    QuerySummaryTable,
    TagSummaryTable,
    CommandSummaryTable,
    CommitSummaryTable,
    SignalInstanceSummaryTable,
    WeblinkSummaryTable,
    WebcrawlSummaryTable,
  },
  data() {
    return {}
  },

  computed: {
    ...mapState("search", ["results", "query", "loading"]),
  },
}
</script>
